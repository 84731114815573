import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185')
];

export const server_loads = [0,2,3,8,24];

export const dictionary = {
		"/": [27],
		"/(protected)/admin/(pages)/alpha-features": [31,[2,3,4]],
		"/(protected)/admin/(pages)/bank-accounts": [32,[2,3,4]],
		"/(protected)/admin/(pages)/businesses": [33,[2,3,4]],
		"/(protected)/admin/(forms)/businesses/new": [28,[2,3]],
		"/(protected)/admin/(pages)/dialogues": [34,[2,3,4]],
		"/(protected)/admin/(pages)/enterprises": [35,[2,3,4]],
		"/(protected)/admin/(forms)/enterprises/new": [29,[2,3]],
		"/(protected)/admin/(pages)/leads": [36,[2,3,4]],
		"/(protected)/admin/(pages)/printers": [37,[2,3,4]],
		"/(protected)/admin/(pages)/readers": [38,[2,3,4]],
		"/(protected)/admin/(pages)/users": [39,[2,3,4]],
		"/(protected)/admin/(forms)/users/new": [30,[2,3]],
		"/api/token/[token]": [~185],
		"/(unprotected)/challenge/[id]": [~172],
		"/(unprotected)/changelog": [173],
		"/(protected)/compare/(pages)/discover": [40,[2,5]],
		"/(protected)/compare/(pages)/menu-analysis": [41,[2,5]],
		"/(protected)/compare/(pages)/monitor": [42,[2,5]],
		"/(protected)/dashboard": [43,[2]],
		"/(unprotected)/error": [174],
		"/(protected)/finance/(pages)": [60,[2,6,7]],
		"/(protected)/finance/(pages)/accounts": [61,[2,6,7]],
		"/(protected)/finance/(forms)/add-funds": [44,[2,6]],
		"/(protected)/finance/(forms)/add-funds/debit": [45,[2,6]],
		"/(protected)/finance/(forms)/add-funds/transfer": [46,[2,6]],
		"/(protected)/finance/(forms)/agreements": [47,[2,6]],
		"/(protected)/finance/(pages)/all-cards": [62,[2,6,7]],
		"/(protected)/finance/(forms)/all-cards/new": [48,[2,6]],
		"/(protected)/finance/(pages)/bills": [63,[2,6,7]],
		"/(protected)/finance/(forms)/create-demand": [49,[2,6]],
		"/(protected)/finance/(forms)/create-penalty": [50,[2,6]],
		"/(protected)/finance/(pages)/expenses": [64,[2,6,7]],
		"/(protected)/finance/(pages)/integrations": [65,[2,6,7]],
		"/(protected)/finance/(forms)/link-account": [51,[2,6]],
		"/(protected)/finance/(forms)/make-payment": [52,[2,6]],
		"/(protected)/finance/(pages)/my-cards": [66,[2,6,7]],
		"/(protected)/finance/(forms)/onboard": [53,[2,6]],
		"/(protected)/finance/(pages)/partners": [67,[2,6,7]],
		"/(protected)/finance/(forms)/pay-bill": [54,[2,6]],
		"/(protected)/finance/(forms)/pay-demand/[id]": [55,[2,6]],
		"/(protected)/finance/(pages)/payment-requests": [68,[2,6,7]],
		"/(protected)/finance/(forms)/pending": [56,[2,6]],
		"/(protected)/finance/(pages)/recipients": [69,[2,6,7]],
		"/(protected)/finance/(forms)/recipients/new": [57,[2,6]],
		"/(protected)/finance/(forms)/rfis": [58,[2,6]],
		"/(protected)/finance/(pages)/spend-limits": [70,[2,6,7]],
		"/(protected)/finance/(forms)/spend-limits/new": [59,[2,6]],
		"/(protected)/food-and-beverages/(pages)/availability": [71,[2,8]],
		"/(protected)/food-and-beverages/(pages)/discounts": [72,[2,8]],
		"/(protected)/food-and-beverages/(pages)/insights": [73,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus": [74,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/active-instances": [75,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/edit": [76,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/publish": [77,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/modifier-groups": [78,[2,8]],
		"/(protected)/food-and-beverages/(pages)/modifier-options": [79,[2,8]],
		"/(protected)/food-and-beverages/(pages)/products": [80,[2,8]],
		"/(protected)/forecast": [~81,[2,10]],
		"/(unprotected)/forgot-password": [175],
		"/(protected)/guests/(pages)/checks": [82,[2,11]],
		"/(protected)/guests/(pages)/customers": [83,[2,11]],
		"/(protected)/guests/(pages)/insights": [84,[2,11]],
		"/(protected)/guests/(pages)/payments": [85,[2,11]],
		"/(protected)/help/[id]": [~86,[2]],
		"/(protected)/inventory/(pages)/allergens": [87,[2,12]],
		"/(protected)/inventory/(pages)/counts/past": [89,[2,12,13]],
		"/(protected)/inventory/(pages)/counts/past/[id]": [90,[2,12,13]],
		"/(protected)/inventory/(pages)/counts/past/[id]/variance/[itemId]": [91,[2,12,13]],
		"/(protected)/inventory/(pages)/counts/recurring": [92,[2,12,13]],
		"/(protected)/inventory/(pages)/counts/upcoming": [93,[2,12,13]],
		"/(protected)/inventory/(pages)/counts/[id]": [88,[2,12,13]],
		"/(protected)/inventory/(pages)/groups": [94,[2,12]],
		"/(protected)/inventory/(pages)/groups/[id]": [95,[2,12]],
		"/(protected)/inventory/(pages)/invoices/new": [97,[2,12]],
		"/(protected)/inventory/(pages)/invoices/[id]": [96,[2,12]],
		"/(protected)/inventory/(pages)/items": [98,[2,12]],
		"/(protected)/inventory/(pages)/items/[id]": [99,[2,12]],
		"/(protected)/inventory/(pages)/locations": [100,[2,12]],
		"/(protected)/inventory/(pages)/measurements/conversions": [101,[2,12]],
		"/(protected)/inventory/(pages)/measurements/table": [102,[2,12]],
		"/(protected)/inventory/(pages)/recipes/prep-items": [103,[2,12]],
		"/(protected)/inventory/(pages)/recipes/prep-items/[id]": [104,[2,12]],
		"/(protected)/inventory/(pages)/vendors": [105,[2,12]],
		"/(protected)/inventory/(pages)/vendors/[id]": [106,[2,12]],
		"/(unprotected)/join/[id]": [176],
		"/(unprotected)/login": [177],
		"/(protected)/overview/(pages)": [111,[2,14]],
		"/(protected)/overview/(pages)/budget-actuals": [113,[2,14]],
		"/(protected)/overview/(pages)/budget": [112,[2,14]],
		"/(protected)/overview/(forms)/budget/create-incidental-cost": [108,[2]],
		"/(protected)/overview/(forms)/budget/create-recurring-cost": [109,[2]],
		"/(protected)/overview/(forms)/budget/[id]": [107,[2]],
		"/(protected)/overview/(pages)/daily-summary": [114,[2,14]],
		"/(protected)/overview/(forms)/dialogue/[id]": [110,[2]],
		"/(protected)/people/(new)/dailies": [115,[2,15]],
		"/(protected)/people/(new)/departments": [116,[2,15]],
		"/(protected)/people/(new)/insights": [117,[2,15]],
		"/(protected)/people/(new)/payroll": [118,[2,15]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]": [119,[2,15]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]/[staffId]": [120,[2,15]],
		"/(protected)/people/(new)/roles": [121,[2,15]],
		"/(protected)/people/(new)/team": [122,[2,15]],
		"/(unprotected)/preauth/[id]": [178,[24]],
		"/(unprotected)/preauth/[id]/scheduling/[business]/[start]": [~179,[24]],
		"/(protected)/profile/address": [123,[2,17]],
		"/(protected)/profile/general": [124,[2,17]],
		"/(protected)/profile/login-history": [125,[2,17]],
		"/(protected)/profile/security": [126,[2,17]],
		"/(protected)/reporting": [127,[2,18]],
		"/(protected)/reporting/bank-deposits": [128,[2,18]],
		"/(protected)/reporting/daily-summary": [~129,[2,18]],
		"/(protected)/reporting/daily-summary/[start]/[comparison]": [~130,[2,18]],
		"/(protected)/reporting/eod": [131,[2,18]],
		"/(protected)/reporting/gift-cards": [132,[2,18]],
		"/(protected)/reporting/gift-cards/balances": [133,[2,18]],
		"/(protected)/reporting/gift-cards/history": [134,[2,18]],
		"/(protected)/reporting/gift-cards/transactions": [135,[2,18]],
		"/(protected)/reporting/reports": [136,[2,18]],
		"/(protected)/reservations/general": [137,[2,19]],
		"/(protected)/reservations/notifications": [138,[2,19]],
		"/(protected)/reservations/online": [139,[2,19]],
		"/(protected)/reservations/reporting": [140,[2,19]],
		"/(protected)/reservations/service-periods": [141,[2,19]],
		"/(unprotected)/reset-password/[token]": [180],
		"/(protected)/scheduling/(pages)/ai/[date]": [~143,[2,20]],
		"/(protected)/scheduling/(pages)/labour-targets": [144,[2,20]],
		"/(protected)/scheduling/(pages)/logs/[date]": [145,[2,20]],
		"/(protected)/scheduling/(pages)/requests": [146,[2,20]],
		"/(protected)/scheduling/(forms)/schedule/[date]": [142,[2]],
		"/(protected)/scheduling/(pages)/schedule/[startDate]/[endDate]": [147,[2,20]],
		"/(protected)/scheduling/(pages)/settings": [148,[2,20]],
		"/(unprotected)/select": [~181,[25]],
		"/(unprotected)/select/business": [~182,[25]],
		"/(protected)/settings/(pages)": [150,[2,21]],
		"/(protected)/settings/(pages)/account": [151,[2,21]],
		"/(protected)/settings/(pages)/general-service": [153,[2,21]],
		"/(protected)/settings/(pages)/general": [152,[2,21]],
		"/(protected)/settings/(pages)/kds": [154,[2,21]],
		"/(protected)/settings/(pages)/kyb": [155,[2,21]],
		"/(protected)/settings/(forms)/kyb/update": [149,[2]],
		"/(protected)/settings/(pages)/online-ordering": [156,[2,21]],
		"/(protected)/settings/(pages)/pos": [157,[2,21]],
		"/(protected)/settings/(pages)/printing": [158,[2,21]],
		"/(unprotected)/sign-up": [183,[26]],
		"/(protected)/store-setup/(pages)/comp-reasons": [159,[2,22]],
		"/(protected)/store-setup/(pages)/floor-plans": [160,[2,22,23]],
		"/(protected)/store-setup/(pages)/kds": [161,[2,22]],
		"/(protected)/store-setup/(pages)/order-kitchen-display": [~162,[2,22]],
		"/(protected)/store-setup/(pages)/printers": [163,[2,22]],
		"/(protected)/store-setup/(pages)/service-areas": [164,[2,22]],
		"/(protected)/store-setup/(pages)/service-fees": [165,[2,22]],
		"/(protected)/store-setup/(pages)/table-management": [166,[2,22]],
		"/(protected)/store-setup/(pages)/taxes": [167,[2,22]],
		"/(protected)/store-setup/(pages)/taxes/[id]": [168,[2,22]],
		"/(protected)/store-setup/(pages)/tip-outs": [169,[2,22]],
		"/(protected)/store-setup/(pages)/void-reasons": [170,[2,22]],
		"/(unprotected)/stripe-reader/register": [184],
		"/(protected)/visualisation": [171,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';